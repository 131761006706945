.phoneInput{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(4, 7, 33, 1);
    }
    .inputBox{
        font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: rgba(4, 7, 33, 1);
    border: none;
    outline: none;
    width: 274px !important;
    background: transparent
    }