.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 128px);
  flex-direction: column;
}

.title {
  font-size: 8rem;
  font-weight: 500;
  color: #040721;
}

.message {
  font-size: 1.5rem;
  color: #6b6b6b;
}

.backButton {
  color: #f9da87;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 50px;
  border: none;
  padding: 8px 12px;
}

.backButton:active {
  background: rgb(57, 57, 57);
}

.backButton a {
  color: #f9da87 !important;
}
