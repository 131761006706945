table.mat-table{
    width: 100%;
  }
  
  .mat-row:nth-child(even){
    background-color:#fff;
  }
  
  .mat-row:nth-child(odd){
    background-color:#f5f5f5;
  }
  
  .mat-cell {
    padding: 8px 75px 0px 15px;
  }
  
  /* td{
    img, span{
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
    a{
      text-decoration: none;
      font-weight: 600;
      color: #464545;
      margin: 0 0 16px;
  }
  }
   */
  input[type=submit] {
    background: #407690;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    height: 50px;
    line-height: px;
    margin: -33px 0 0 5px;
    margin-bottom: 30px;
    margin-left: 100px;
    width: 12em;
  }
  
  .errspan {
    float: right;
    margin-right: -250px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: #fff;
  }
  