.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #040721bf;
  position: fixed;
  z-index: 9;
  top: 0;
}

.bodyBox {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  z-index: 7;
  padding: 24px;
  position: relative;
  margin: 26px;
}

.heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #040721;
  margin-bottom: 20px;
}

.inputContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.inputBox {
  width: 70%;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #646467;
  outline: none;
  margin-bottom: 6px;
}
.closeIcon {
  text-align: right;
  cursor: pointer;
  font-size: 18px;
}

.header {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #040721;
  margin-bottom: 12px;
}
.label {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #040721;
  margin-bottom: 12px;
}

.footer {
  display: flex;
  bottom: 24px;
  width: calc(100% - 48px);
  margin-top: 12px;
}

.btn1 {
  margin-bottom: 10px;
  margin-right: 24px;
}

.btn2 {
  margin-right: 24px;
}

.successBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMsg {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff3535;
  margin-bottom: 12px;
}

.bodyPadding {
  padding-bottom: 58px;
  padding-top: 26px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
}

button:disabled {
  color: white !important;
  background: gray !important;
  border-color: #040721 !important;
}
