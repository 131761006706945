.container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 20px;
  gap:16px;
}
.loader {
  margin-left: "12px";
  color: "#a0a0a0";
  font-size: "24px";
}
.header {
  font-weight: bold;
  color: #3d3d3d;
}
.ui-datepicker-calendar {
    display: none;
 }
 .ui-datepicker-month {
    display: none;
 }
 .ui-datepicker-next,.ui-datepicker-prev {
   display:none;
 }
 .dateStyle{
    margin-top: 12px;
 }