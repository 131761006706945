mat-drawer {
  width: 300px;
  padding: 30px;
}

.mat-toolbar.mat-primary {
  background: #f5f5f5;
  color: #040721;
}

main {
  font-size: 1em;
  padding: 30px;
}

mat-toolbar {
  padding-left: 30px;
}

mat-drawer-container {
  height: 100% !important;
}
.mat-drawer-backdrop {
  background-color: transparent;
}

.menu-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}
.menu-button.rotated {
  transform: rotate(180deg);
}
.submenu {
  overflow-y: hidden;
  transition: 300ms ease-in-out;
  height: 0;
}
.submenu.expanded {
  height: 100%;
}

.authorized-user-container {
  height: 800px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.authorized-user-sidenav-content {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.authorized-user-sidenav {
  user-select: none;
}
.full-width {
  width: 100%;
}
.menu-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}
.menu-button.rotated {
  transform: rotate(180deg);
}
.submenu {
  overflow-y: hidden;
  transition: transform 300ms ease;
  transform: scaleY(0);
  transform-origin: top;
  padding-left: 30px;
}
.submenu.expanded {
  transform: scaleY(1);
}

.authorized-spacer {
  flex: 1 1 auto;
}

.ylb-app-content {
  padding: 16px;
}

.logout {
  display: inline-block;
  width: 100%;
  text-align: right;
  vertical-align: bottom;
  font-size: 14px;
}

.mat-menu-item:hover {
  color: #aa3c9f;
}
.mat-menu-item:hover .mat-icon {
  color: #aa3c9f;
}

.menuUl {
  list-style-type: none;
  text-decoration: none;
  padding-left: 45px;
}
.menuli {
  color: black !important;
  text-decoration: none !important;
}
.menuUl li {
  color: black;
  text-decoration: none;
}
.menuLink {
  text-decoration: none !important;
  color: black !important;
}

.menuLink:hover {
  text-decoration: none !important;
  color: black !important;
}
