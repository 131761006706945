.login-card {
  width: 400px;
  min-height: 350px;
  text-align: center;
  background-color: red;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.mat-card-content {
  padding: 50px 50px 0px 50px;
}

.MuiBox-root {
  width: 100%;
  /* white-space: nowrap; */
  padding: 0;
}

.dataHeader {
  display: flex;
}

.formGroup {
  margin-bottom: 26px;
}

.error-msg {
  color: #f00;
}

.tabStyle {
  text-transform: capitalize !important;
  outline: none !important;
}
.mainContainer {
  display: flex;
  padding-left: 14px;
  justify-content: space-between;
  align-items: center;
}
.riskInputContainer {
  display: flex;
  flex-direction: column;
}
.riskLabel {
  font-weight: 700;
}
.riskInput {
  width: 289px !important;
  border: none !important;
  outline: none !important;
  background: none !important;
}
.achLedgerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 20px;
}
.listBox {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.listItem {
  padding: 8px;
  border-bottom: solid 1px #e8e7e7;
  display: flex;
}

.itemHead {
  width: 220px;
  font-weight: bold;
  color: #5a5a5a;
  margin-right: 24px;
}

.searchUserHeader {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 20px;
  font-weight: 700;
  /* text-decoration: underline; */
}

.lastwiretime {
  font-size: 20px;
  margin-left: 14px;
}

.mt15 {
  margin-top: 15px;
}

.matSelect {
  height: 47px;
  border: none;
  border-bottom: solid 1px #767676;
  background: #ccc0;
  color: #6b6b6b;
  padding-top: 12px;
  outline: none;
}

.inputError {
  border-radius: 4px;
  border: solid 1px #dc3545 !important;
  border-color: #dc3545 !important;
  outline: 0;
}

.inputError:hover {
  border: solid 2px #dc3545 !important;
  border-color: #dc3545 !important;
  outline: 0;
}

.formDiv {
  padding: 0 8px;
}

.form-label {
  height: 37px !important;
}

.row button {
  margin: 0;
}

.bankAddRuleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listItem .form-control {
  min-width: 500px;
}

.searchInput {
  /* border: none; */
  border-color: none;
  outline: 0;
  box-shadow: none !important;
  /* border-bottom: solid 1px #979797; */
  border-radius: 0;
  margin-right: 8px;
  height: 30px;
}

.searchInput:focus {
  border: solid 1px #0073ee;
}

.MuiTablePagination-toolbar p {
  margin-bottom: 0;
}

.inputBox {
  width: 65%;
  height: 50px;
}
.formDiv {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.filesPgp {
  margin-top: 22px;
  width: 60%;
  overflow-x: hidden;
}

.ginpu1 {
  width: 100%;
  padding: 0 8px;
  border: solid 1px #ccc;
}

.ginput2 {
  width: 100%;
  height: 40px;
  border: solid 1px #ccc;
  background: #fff;
  outline: none;
  padding: 0 4px;
}

.listHeaderPaddingRight {
  padding-right: 48px;
  transition: padding-right linear 0.2s;
}

.dynamicTableStyle {
  padding-right: 0 !important;
  transition: padding-right linear 0.2s;
}

.MuiTableBody-root tr {
  transition: all linear 1s;
}

select.form-control {
  -webkit-appearance: menulist !important;
}

.textDecorationNone {
  text-decoration: "none";
}

.headerTitle {
  display: flex;
  padding: 1.5rem 0;
  align-items: center;
}

.loaderBox {
  margin-left: 12px;
  color: #a0a0a0;
  font-size: 24px;
}

.filterBox {
  display: flex;
  align-items: end;
}

.linkColor {
  color: #040721 !important;
}

.height38 {
  height: 38px !important;
}

.storeSampleDownload {
  margin-left: 16px;
}

.MuiInputLabel-formControl {
  width: fit-content;
}
.csvData {
  padding: 10px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.grey {
  background: #909497;
}

.red {
  background: red;
}

.yellow {
  background: yellow;
}

.green {
  background: #14e847;
}

.userDetails {
  margin-top: 20px;
}

.userControls {
  margin: 8px;
}

.riskScore {
  max-width: 400px;
}

.kycStatus {
  display: flex;
}

.kycStatusText {
  margin-left: 10px;
}

.unblockUserBtn {
  margin-left: 20px;
}

.limits {
  margin: 14px;
}

.limitsHeadingText {
  font-weight: 700;
  margin-bottom: 10px;
}

.hightlight {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.lineBreak {
  background: grey;
  height: 0.5px;
  margin: 12px 0px;
}
.linkedItems {
  text-wrap: wrap;
  text-align: left;
  text-overflow: wrap;
}
