.form-row {
  overflow: hidden;
  padding: 10px;
  font-size: 15px;
  border-bottom: 1px solid #eee;
}
label {
  display: block;
  padding: 4px 10px 0 0;
  float: left;
  width: 160px;
  line-height: 3;
}
input {
  height: 40px;
  line-height: 5px;
  border: 1px solid #eee;
  margin-bottom: 0;
  width: 30em;
}
.aligned {
  margin-bottom: 0;
  display: block;
  line-height: 1;
}
input[type="submit"] {
  background: #79aec8;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  height: 50px;
  line-height: px;
  margin: 0 0 0 5px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 100px;
  width: 20em;
}
label.required {
  font-weight: bold;
  color: #333;
}

/* button {
  margin: 16px 8px;
} */

/* ::ng-deep .checkBoxNew{width:30px !important;height:30px!important;} */
