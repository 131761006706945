.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #eee;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(132, 132, 132);
  border-radius: 8px;
}

.drawer-menu {
  margin-top: 65px;
  position: fixed;
  z-index: 100;
}

.drawer-menu-items {
  overflow-y: overlay;
  height: calc(100vh - 66px);
  padding-top: 12px;
  width: 100%;
  overflow-x: hidden;
}

.drawer-menu-items > li:hover {
  background: #f9da8792;
  color: #040721;
}

.drawer-menu-items > div.MuiCollapse-root .menuUl:hover {
  background: #f9da8792;
  color: #040721;
}

.drawer-menu-items > div.MuiCollapse-root .menuUl:hover .menuLink span,
.drawer-menu-items > div.MuiCollapse-root .menuUl:hover .menuLink svg {
  color: #040721 !important;
}

.drawer-menu-items > li:hover svg {
  color: #040721;
}

.form-control:focus,
.form-select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #040721;
  border-width: 2px;
  outline: 0;
  box-shadow: none;
  /* box-shadow: 0 0 0 0.25rem #f9da87; */
}

.form-control:not(textarea),
.form-select {
  height: 3rem;
}

.btn-primary {
  color: #f9da87;
  background: #040721;
  border-color: #040721;
}

.btn-primary:hover {
  color: #040721;
  background: #f9da87;
  border-color: #f9da87;
}

.btn-primary:focus {
  box-shadow: none;
  color: #040721;
  background: #f9da87;
  border-color: #f9da87;
}

.btn:focus {
  box-shadow: none;
}

.download-btn {
  background-color: #f9da87;
}
.download-btn:hover {
  background-color: #f8d269;
}

.form-check-input:checked {
  background-color: #040721;
  border-color: #040721;
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
}

.form-check-input {
  width: 1.6em;
  height: 1.6em;
  border-color: #040721;
}

.form-check-input:focus {
  border: solid 2px #000;
  box-shadow: none;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none;
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  box-shadow: none;
}

.invalid-form-input {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url('data:image/svg + xml,
  %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 12 12"width="12"height="12"fill="none"stroke="%23dc3545"%3e%3ccirclecx="6"cy="6"r="4.5"/%3e%3cpathstroke-linejoin="round"d="M5.8 3.6h.4L6 6.5z"/%3e%3ccirclecx="6"cy="8.2"r=".6"fill="%23dc3545"stroke="none"/%3e%3c/svg%3e'); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em em + 0.375rem);
}

.invalid-form-input:focus {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url('data:image/svg + xml,
  %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 12 12"width="12"height="12"fill="none"stroke="%23dc3545"%3e%3ccirclecx="6"cy="6"r="4.5"/%3e%3cpathstroke-linejoin="round"d="M5.8 3.6h.4L6 6.5z"/%3e%3ccirclecx="6"cy="8.2"r=".6"fill="%23dc3545"stroke="none"/%3e%3c/svg%3e'); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em em + 0.375rem);
}

.valid-form-input {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url('data:image/svg + xml,
  %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 8 8"%3e%3cpathfill="%23198754"d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"/%3e%3c/svg%3e'); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.swal2-container {
  z-index: 9999 !important;
}

#nprogress .bar {
  height: 0.3rem !important;
  z-index: 9999999 !important;
  /* background: linear-gradient(90deg, #040721, #f9da87) !important; */
  background: #040721 !important;
}

#nprogress .peg {
  box-shadow: none !important;
}

#nprogress .spinner {
  display: none !important;
}
